import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import ClipLoader from "react-spinners/ClipLoader";
import AnimatedSection from "../animations/animatedSection";
import { useSelector } from "react-redux";

const AllGraphicsProjects = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const { graphicsProjects, loadingGraphicsProjects, errorGraphicsProjects } =
    useSelector((state) => state);

  const handleProjectClick = (projectId) => {
    navigate(`/graphics/project/${projectId}`);
  };

  // Function to clean and parse skills
  const cleanSkills = (skills) => {
    return skills
      .map((skill) => skill.replace(/[[\]"]/g, "").trim())
      .filter((skill) => skill.length > 0);
  };

  if (loadingGraphicsProjects) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader
          color="#000000"
          loading={loadingGraphicsProjects}
          size={100}
        />
      </div>
    );
  }

  if (errorGraphicsProjects) {
    return (
      <div className="text-center text-red-500">{errorGraphicsProjects}</div>
    );
  }

  return (
    <AnimatedSection>
      <div className="bg-primary min-h-screen mt-16 p-8 flex flex-col gap-8">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl font-bold text-secondary mb-4">Projects</h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {graphicsProjects.map((project) => (
            <div
              key={project.id}
              className="bg-primary border border-secondary p-4 rounded-lg shadow-lg text-secondary hover:bg-slate-100 transition duration-300"
            >
              <div onClick={() => handleProjectClick(project.id)}>
                <h2 className="text-xl font-bold mb-2 transition duration-200">
                  {project.title}
                </h2>
                <img
                  src={project.thumbnail}
                  alt="Project thumbnail"
                  className="w-full h-auto rounded-lg mb-4"
                />
                <div
                  className="long-description text-sm mb-4"
                  dangerouslySetInnerHTML={{ __html: project.description }}
                />
                <p className="text-sm text-gray-400 mb-2">
                  <strong>Skills and Deliverables:</strong>{" "}
                  {project.skills_and_deliverables &&
                  project.skills_and_deliverables.length > 0
                    ? cleanSkills(project.skills_and_deliverables).join(", ") // Clean and join skills
                    : "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AnimatedSection>
  );
};

export default AllGraphicsProjects;
