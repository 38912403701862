import { API_IC, Fullstack_IC, Linux_IC, Graphics_IC } from "../assets/banners";

const services = [
  {
    id: 1,
    title: "API Development",
    description:
      "I offer professional API development services, creating secure and efficient RESTful APIs to power your web and mobile applications. Whether it's integrating third-party services or building custom solutions, I ensure seamless communication between systems.",
    banner: API_IC,
  },
  {
    id: 2,
    title: "Web Development",
    description:
      "I specialize in full-stack web development, building responsive and scalable websites and web applications tailored to your business needs. From frontend design to backend functionality, I deliver complete solutions using modern technologies.",
    banner: Fullstack_IC,
  },
  {
    id: 3,
    title: "Backend Development",
    description:
      "I provide backend development services focused on creating reliable, high-performance systems. With expertise in server-side logic, database management, and system integration, I ensure your applications are robust and scalable.",
    banner: Linux_IC,
  },
  {
    id: 4,
    title: "Consulting Services",
    description:
      "I offer expert consulting services to help you make the right technology choices for your projects. From strategy to implementation, I provide guidance on web development, software design, and system architecture.",
    banner: Graphics_IC,
  },
];

export default services;
