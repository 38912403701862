import React, { useState, useEffect } from "react";
import validator from "validator";
import { useForm, ValidationError } from "@formspree/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Contact_Banner } from "../assets/banners";
import { useLocation, useNavigate } from "react-router-dom";

function Contact() {
  const [state, handleSubmit] = useForm("mjkbbkvz");
  const [validEmail, setValidEmail] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  function verifyEmail(email) {
    if (validator.isEmail(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Email successfully sent!", {
        position: "bottom-left",
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: "succeeded",
      });
    }
  }, [state.succeeded]);

  const handleBackToTopClick = () => {
    if (location.pathname === "/contact") {
      // If the user is on the contact page, navigate to the homepage
      navigate("/");
    } else {
      // If the user is on the homepage, scroll to the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <section className="bg-primary border border-secondary rounded-lg rounded-md m-4 lg:m-12 p-4">
      <div className="flex flex-col items-center justify-center">
        {/* Conditional Rendering for Thank You Message */}
        {state.succeeded ? (
          <div className="flex flex-col items-center justify-center gap-12 px-6 text-center">
            <h3 className="text-2xl md:text-3xl text-primary font-bold">
              Thank you for reaching out! I will get back to you shortly!
            </h3>
            <button
              className="bg-secondary hover:bg-blue-700 rounded-lg px-6 py-3 text-white font-bold text-xl md:text-2xl transition-all duration-300"
              onClick={handleBackToTopClick}
            >
              {location.pathname === "/contact" ? "Go to Home" : "Back to the Top"}
            </button>
          </div>
        ) : (
          <div className="w-full max-w-3xl px-6">
            <h1 className="text-3xl md:text-4xl text-center text-secondary font-bold my-8">
              Let's Connect and Create Something Extraordinary!
            </h1>
            <p className="text-lg md:text-xl text-secondary text-center mb-8">
              Have a question, a brilliant idea, or a collaboration in mind? I’d
              love to hear from you! Fill out the form below, and I promise to
              respond promptly.
            </p>
            <form
              className="flex flex-col justify-center items-center gap-6"
              onSubmit={handleSubmit}
            >
              {/* Email Input */}
              <div className="w-full lg:w-3/4">
                <input
                  className="w-full border border-gray-400 rounded-md bg-primary text-secondary p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                  placeholder="Enter your best email..."
                  id="email"
                  type="email"
                  name="email"
                  onChange={(e) => verifyEmail(e.target.value)}
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>

              {/* Message Input */}
              <div className="w-full lg:w-3/4">
                <textarea
                  className="w-full border border-gray-400 rounded-md bg-primary text-secondary p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                  placeholder="Tell me how I can help you or share your big idea here..."
                  id="message"
                  name="message"
                  rows="5"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>

              {/* Submit Button */}
              <button
                className={`bg-secondary hover:bg-blue-700 rounded-lg px-6 py-3 text-white font-bold text-xl transition-all duration-300 ${state.submitting || !validEmail || !message
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100"
                  }`}
                type="submit"
                disabled={state.submitting || !validEmail || !message}
              >
                SUBMIT
              </button>
            </form>
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>

  );
}

export default Contact;
