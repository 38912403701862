import React, { useEffect, useState } from "react";
import { Github, LinkIc } from "../assets/icons";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import AnimatedSection from "../animations/animatedSection";
import { styleContent } from "../utils/contentStyler";
import { useSelector } from "react-redux";

const SingleProject = () => {
  const { id } = useParams();
  const [styledContent, setStyledContent] = useState("");

  // Accessing the projects list and loading state from Redux
  const {
    programmingProjects,
    loadingProgrammingProjects,
    errorProgrammingProjects,
  } = useSelector((state) => ({
    programmingProjects: state.programmingProjects,
    loadingProgrammingProjects: state.loadingProgrammingProjects,
    errorProgrammingProjects: state.errorProgrammingProjects, // Adjust this key to match your actual loading state
  }));

  const project = programmingProjects?.find((proj) => proj.id === parseInt(id));

  useEffect(() => {
    if (project) {
      const styledHtml = styleContent(project.long_description);
      setStyledContent(styledHtml);
    }
  }, [project, loadingProgrammingProjects]);

  if (loadingProgrammingProjects) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader
          color="#000000"
          loading={loadingProgrammingProjects}
          size={100}
        />
      </div>
    );
  }

  if (errorProgrammingProjects) {
    return (
      <div className="min-h-screen text-4xl bg-primary text-secondary text-center flex justify-center items-center">
        {errorProgrammingProjects}
      </div>
    );
  }

  // Render only if the project is defined
  if (!project) return null;

  return (
    <AnimatedSection>
      <main className="gap-8 mt-20 p-8 rounded-xl w-full min-h-screen text-secondary">
        <div className="gap-4">
          <h1 className="text-2xl mb-2 font-bold">{project.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: styledContent,
            }}
          ></div>

          <p className="text-gray-600 mb-2">
            <strong>Languages:</strong> {project.languages.join(", ")}
          </p>
          <p className="text-gray-600 mb-4">
            <strong>Frameworks:</strong> {project.frameworks.join(", ")}
          </p>

          <div className="flex gap-8">
            {project.github_link && (
              <a
                href={project.github_link}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
              >
                <img src={Github} alt="GitHub icon" className="w-8 md:w-10" />
              </a>
            )}
            {project.deployed_link && (
              <a
                href={project.deployed_link}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
              >
                <img src={LinkIc} alt="Link icon" className="w-8 md:w-10" />
              </a>
            )}
          </div>

          {/* Displaying screenshots */}
          <div className="flex flex-col gap-4 mt-4">
            <p className="text-2xl font-bold text-center">Screenshots</p>
            {project.screenshots &&
              project.screenshots.map((screenshot, index) => (
                <img
                  key={index}
                  src={screenshot}
                  alt={`Screenshot ${index + 1} of ${project.title}`}
                  className="w-full rounded-lg shadow-lg"
                />
              ))}
          </div>
        </div>
      </main>
    </AnimatedSection>
  );
};

export default SingleProject;
