import React from "react";
import { linkedIn, Github, WhatsApp, Telegram } from "../assets/icons";
import Programmer from "../assets/gifs";
import { description, position } from "../constants/texts";
import { Link } from "react-router-dom";
import FadeInImage from "../animations/animatedImage";
// Create a functional component
function Hero({ onContactClick }) {

  // Return JSX
  return (
    <section className="App bg-primary h-full flex flex-col md:flex-row lg:px-2 lg:pt-10 pt-20 px-4 md:px-16">
      {/* Text and Button Section */}
      <div className="w-full md:w-2/3 flex flex-col text-left p-4 md:p-16 gap-6">
        <p className="text-secondary text-lg md:text-xl">{"Hello, I am"}</p>
        <p className="text-4xl md:text-7xl text-secondary">{"Bikila Ketema"}</p>
        <p className="text-lg md:text-xl text-secondary font-bold">{position}</p>
        <p
          className="text-sm md:text-base text-secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {description}
        </p>
        <div className="flex flex-col gap-4 lg:items-start items-center justify-center">
          <Link
            to="/contact"
            className="text-center bg-secondary rounded-lg w-full md:w-1/3 text-white p-4 text-lg md:text-2xl"
          >
            Contact
          </Link>
          {/*                        <Link
          to="https://www.upwork.com/freelancers/~0196ff992dea799538"
          className="text-center bg-blue rounded-lg w-full md:w-1/3 text-secondary p-4 text-lg md:text-2xl whitespace-nowrap"
        >
          Hire me on Upwork
        </Link> */}
          <div className="flex flex-wrap gap-4 justify-center items-center md:justify-start">
            <a
              href="https://www.linkedin.com/in/bikilaketema"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedIn} alt="linkedin icon" className="w-8 md:w-10" />
            </a>
            <a
              href="https://github.com/bikilaketema"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Github} alt="github icon" className="w-8 md:w-10" />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B251942932231&text=Hello+Bikila"
              target="_blank"
              rel="noreferrer"
            >
              <img src={WhatsApp} alt="whatsapp icon" className="w-8 md:w-10" />
            </a>
            <a href="https://t.me/bikilaketema" target="_blank" rel="noreferrer">
              <img src={Telegram} alt="telegram icon" className="w-8 md:w-10" />
            </a>
          </div>
        </div>
      </div>
      {/* Image Section */}
      <div className="relative w-full md:w-1/3 flex justify-center items-center mt-8 md:mt-0">
        <FadeInImage src={Programmer} alt="programmer" />
        <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-85"></div>
      </div>
    </section>
  );
}

// Export the component
export default Hero;
