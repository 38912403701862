import React from "react";
import Contact from "./contact";
import About from "./about";
import Hero from "./hero";
//import Education from "./education";
import AnimatedSection from "../animations/animatedSection";
//import Testimonials from "./testimonials";
import Services from "./services";

// Create a functional component
function Home() {
  // Return JSX
  return (
    <div className="bg-primary">
      <AnimatedSection delay={0.2}>
        <Hero />
      </AnimatedSection>

      <AnimatedSection delay={0.2}>
        <About />
      </AnimatedSection>

{/*       <AnimatedSection delay={0.2}>
        <Education />
      </AnimatedSection> */}

      <AnimatedSection delay={0.2}>
        <Services />
      </AnimatedSection>

{/*       <AnimatedSection delay={0.2}>
        <Testimonials />
      </AnimatedSection> */}

      <AnimatedSection delay={0.2}>
        <Contact />
      </AnimatedSection>
    </div>
  );
}

// Export the component
export default Home;
