import { React } from "react";
import services from "../constants/Services";

function Services() {
  return (
    <section className="lg:p-2 md:p-8 pt-24 md:pt-24">
      <h1 className="text-2xl md:text-3xl text-secondary font-bold text-center mb-6 md:mb-8">
        Services
      </h1>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 md:gap-8">
        {services.map((skill) => (
          <div
            key={skill.id}
            className="border border-secondary p-4 m-4 rounded-lg shadow-md bg-primary text-secondary flex flex-col gap-4"
          >
            <div className="relative flex flex-row justify-center mb-4 h-[60%] items-center">
              <img
                src={skill.banner}
                alt="skill banner"
                className="rounded-lg"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-85"></div>
            </div>

            <h2 className="text-xl md:text-lg font-bold mb-4">{skill.title}</h2>
            <p className="text-gray-300 text-sm mb-4">{skill.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
